body {
  font-family: 'Inter', sans-serif !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

* {
  user-select: none;
}

.text-truncate-player {
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 600px) {
  html {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  body,
  .header,
  .footer {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }

  .header h3 {
    padding: 0 !important;
  }

  .w-22 {
    width: 22% !important;
  }

  .modal-dialog {
    justify-content: center !important;
    width: 380px !important;
  }
}

@media (max-width: 600px) {
  .w-100-modelBox {
    width: 100% !important;
  }
}

.header {
  background: linear-gradient(90deg, #C00000 0%, #CE4A00 50%, #C00000 100%);
  color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1030;
}

.footer {
  background: #383838;
  height: 60px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1030;
  flex-shrink: 0;
}

.footerButton {
  border: 0.8px solid #C00000;
  background: linear-gradient(90deg, #FF5C00 0%, #C00000 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}


main {
  /* background: #FAFAFA !important; */
  max-height: 100% !important;
  min-height: 100vh !important;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.h-180 {
  height: 180px;
  min-height: 180px;
}

.header-aside {
  width: 130px;
  height: 32px;
}

.header-aside-join {
  width: 110px;
  height: 32px;
}

.notification {
  background: linear-gradient(90deg, #393939 0%, #616161 100%);
}

.redShadowBg {
  background: linear-gradient(90deg, #FF5C00 0%, #C00000 100%);
}

.redShadowBg2 {
  background: linear-gradient(90deg, #C00000 0%, #FF5C00 100%);
}

.lightRed {
  background: rgba(255, 0, 0, 0.1) !important;
}

.fs-12 {
  font-size: 12px;
}

.fs-10 {
  font-size: 10px;
}

.lightYellowBg {
  background: #FFF3C8;
}

.greenShadowBg {
  background: linear-gradient(90deg, #00A925 0%, #00C62C 100%);
}

.lightGrayBg {
  background: #EDEDED;
}

.custom-img {
  width: 40px;
  height: 40px;
}

.scoreCircle {
  width: 30px;
  height: 30px;
}

.custom-img-30 {
  width: 35px;
  height: 35px;
  font-size: 12px;
}

.custom-img-25 {
  width: 25px;
  height: 25px;
}

.custom-img-20 {
  width: 20px;
  height: 20px;
}

.bg-backgroundImg {
  background-image: url("/public/assets/background.svg");
  background-size: 130% 130%;
  background-position: center;
  background-repeat: no-repeat;
}

.text-gradient {
  background-image: linear-gradient(90deg, #C00000 0%, #FF5C00 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.absolute-image {
  position: absolute;
  top: 0;
  right: 0;
}

.bodyBgColor {
  background: #FAFAFA;
}

.modelHeader {
  background: linear-gradient(90deg, #C00000 0%, #CE4A00 50%, #C00000 100%);
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-30 {
  width: 30% !important;
}

.w-20 {
  width: 20% !important;
}

.letter-spacing-negative {
  letter-spacing: -0.5px;
}

.skyBlueBg {
  background: #e2ecf8;
}

.activeButton {
  border: 2px solid #cccccc;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  outline: 2px solid green;
  outline-offset: 2px;
  padding: 10px 20px;
  background-color: gray;
  color: #333;
  cursor: pointer;
}

.activeButton:focus {
  outline: 2px solid #007bff;
}

table tr td,
table tr th {
  --bs-table-bg: transparent !important;
  border: 0px;
}

.h-15 {
  height: 15vh !important;
}

.h-20 {
  height: 20vh !important;
}

@media (min-width: 400px) {
  .px-smm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .mx-smm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .text-smm-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .w-smm-85 {
    width: 85% !important;
  }
}

@media (max-width: 400px) {
  .mx-smm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .w-smm-100 {
    width: 100% !important;
  }
}

.disabledDiv {
  pointer-events: none;
  opacity: 0.5;
}

input[type="date"]::before {
  content: attr(placeholder);
  position: absolute;
  color: #999999;
}

input[type="date"] {
  color: #ffffff;
}

input[type="date"]:focus,
input[type="date"]:valid {
  color: #666666;

}

input[type="date"]:focus::before,
input[type="date"]:valid::before {
  content: "";

}

.dot {
  width: 6px;
  height: 6px;
  background-color: white;
  border: 1px solid grey;
  transition: background-color 1s ease;
}

.active {
  width: 8px;
  height: 8px;
  background-color: black;
}

/* .slider__item {
  transition-duration: 500ms;
  transition-property: margin-right;
  min-width: 100%;
  border: 1px solid #e1d8d8;
} */

.image-container {
  position: relative;
  width: 100%;
  height: 180px;
}

.slider__item {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.slider__item-visible {
  opacity: 1;
}

.w-380 {
  width: 380px !important;
}

.h-40 {
  min-height: 40vh;
}

.h-80 {
  min-height: 80vh;
}

input:focus {
  outline: none;
  border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.card {
  background-color: #fff;
  height: auto;
  width: auto;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 9px 17px 45px -29px rgba(0, 0, 0, 0.44);

}

.card__title.loading {
  height: 1rem;
  width: 50%;
  margin: 1rem;
  border-radius: 3px;
}

.card__description.loading {
  height: 3rem;
  margin: 1rem;
  border-radius: 3px;
}

.card__image.loading {
  min-width: 100%;
}

.notification__title.loading {
  height: 2rem;
  width: 25%;
  background: transparent;
  border-radius: 3px;
}

.loading {
  position: relative;
  background-color: #e2e2e2;
}

.loading::after {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background: linear-gradient(to right,
      transparent,
      rgba(255, 255, 255, 0.2) 20%,
      transparent);


  background: linear-gradient(90deg, transparent,
      rgba(255, 255, 255, 0.2), transparent);

  animation: loading 1s infinite;
}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}

.p-20 {
  padding: .20rem !important;
}

.selected-amount {
  background-color: #6c757d;
  outline: 2px solid #2c54ce;
  outline-offset: 2px;
}

.rotate {
  animation: rotate 0.5s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}